import React from 'react'

const initialState = { isActive: false }
const AuthContext = React.createContext(initialState)
const { Provider } = AuthContext

const actions = {
  NEW_USER: 'NEW_USER'
}
const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer((state, action) => {
    console.log({ action })
    switch (action.type) {
      case actions.NEW_USER: {
        const newState = {
          user: action.payload.user
        }
        return newState
      }
      default:
        throw new Error()
    }
  }, initialState)
  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return {
    ...context.state,
    dispatch: context.dispatch,
    actions
  }
}

export { useAuth, AuthProvider }
