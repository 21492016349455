import React from 'react'
import { css } from '@emotion/core'
import { Box, Title, settings } from 'boostly-ui2'
import Pill from './pill'

const Label = ({ children, onDark, color, size }) => (
  <Title size={size} color={onDark ? 'white' : color}>
    {children}
  </Title>
)

const Capsule = ({ x, children, onClick }) => (
  <Box
    onClick={onClick}
    position='absolute'
    left={0}
    css={css`
      cursor: pointer;
      transition: 0.2s;
      transform: translateX(${x}%);
    `}
  >
    {children}
  </Box>
)

const makeArrayOfLength = size => Array.from(Array(size), (d, i) => i)

const pillDimensions = {
  h: 26,
  w: 60
}
const Menu = ({
  children: items,
  defaultIndex,
  onSelect,
  color = settings.default.purple,
  fontSize = '12px',
  dimensions = pillDimensions
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(defaultIndex)
  const [positions, setPositions] = React.useState([])
  const containerRef = React.useRef()
  const onClick = i => {
    setSelectedIndex(i)
    onSelect(i)
  }
  const calcPositions = el => {
    const containerWidth = el.offsetWidth
    const diff = containerWidth - items.length * dimensions.w
    const breakCount = items.length - 1
    const breakSize = diff / breakCount
    const itemW = dimensions.w
    setPositions(
      makeArrayOfLength(items.length).map(
        i => ((i * itemW + breakSize * i) / itemW) * 100
      )
    )
  }
  React.useEffect(() => {
    calcPositions(containerRef.current)
    const handleResize = () => calcPositions(containerRef.current)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <Box ref={containerRef} position='relative' w='100%' height={dimensions.h}>
      <Capsule x={positions[selectedIndex]}>
        <Pill bg={color} {...dimensions} />
      </Capsule>
      {items.map((label, i) => (
        <Capsule
          x={positions[i]}
          onClick={() => onClick(i)}
          position='absolute'
          key={i}
        >
          <Pill bg='transparent' {...dimensions}>
            <Label onDark={i === selectedIndex} color={color} size={fontSize}>
              {label}
            </Label>
          </Pill>
        </Capsule>
      ))}
    </Box>
  )
}

export default Menu
