import React from 'react'
import { Row } from 'boostly-ui2'

const Pill = ({ children, bg, w, h, x = true }) => (
  <Row bg={bg} w={w + 'px'} height={h + 'px'} borderRadius='25px' x={x} y>
    {children}
  </Row>
)

export default Pill
