import React from 'react'
const Close = ({ size = '20px' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 20 20'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke='none' fill='none' fillRule='evenodd'>
      <path
        d='M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M10.0707107,8.51507576 L6.88873016,5.33309524 C6.69346802,5.1378331 6.37688553,5.1378331 6.18162338,5.33309524 L5.33309553,6.1816231 C5.13783338,6.37688524 5.13783338,6.69346773 5.33309553,6.88872988 L8.51507604,10.0707104 L5.40380592,13.1819805 C5.20854378,13.3772427 5.20854378,13.6938252 5.40380592,13.8890873 L6.1109127,14.5961941 C6.30617485,14.7914562 6.62275734,14.7914562 6.81801948,14.5961941 L9.9292896,11.484924 L13.1112701,14.6669045 C13.3065323,14.8621666 13.6231148,14.8621666 13.8183769,14.6669045 L14.6669048,13.8183766 C14.8621669,13.6231145 14.8621669,13.306532 14.6669048,13.1112698 L11.4849242,9.92928932 L14.5961941,6.81801948 C14.7914562,6.62275734 14.7914562,6.30617485 14.5961941,6.1109127 L13.8890873,5.40380592 C13.6938252,5.20854378 13.3772427,5.20854378 13.1819805,5.40380592 L10.0707107,8.51507576 Z'
        fill='#FFFFFF'
      />
    </g>
  </svg>
)

export default Close

export const CloseOutlined = () => (
  <svg
    width='16px'
    height='16px'
    viewBox='0 0 16 16'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='dashboard'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g transform='translate(-296.000000, -42.000000)' stroke='#08080C'>
        <g transform='translate(296.000000, 42.000000)'>
          <circle
            id='Oval'
            fill='#FFFFFF'
            opacity='0.900809152'
            cx='8'
            cy='8'
            r='7.5'
          />
          <polyline
            strokeWidth='2'
            strokeLinecap='round'
            points='5 5 8.06 8 11.12 11'
          />
          <polyline
            strokeWidth='2'
            strokeLinecap='round'
            points='5 11 8.06 8 11.12 5'
          />
        </g>
      </g>
    </g>
  </svg>
)
