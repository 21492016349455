import React from 'react'
const More = () => (
  <svg
    width='32px'
    height='12px'
    viewBox='0 0 32 12'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g fill='#8147FF' fillRule='nonzero'>
        <path d='M6,0 L26,0 C29.3137085,-6.08718376e-16 32,2.6862915 32,6 C32,9.3137085 29.3137085,12 26,12 L6,12 C2.6862915,12 4.05812251e-16,9.3137085 0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z M7,3 C5.35,3 4,4.35 4,6 C4,7.65 5.35,9 7,9 C8.65,9 10,7.65 10,6 C10,4.35 8.65,3 7,3 Z M25,3 C23.35,3 22,4.35 22,6 C22,7.65 23.35,9 25,9 C26.65,9 28,7.65 28,6 C28,4.35 26.65,3 25,3 Z M16,3 C14.35,3 13,4.35 13,6 C13,7.65 14.35,9 16,9 C17.65,9 19,7.65 19,6 C19,4.35 17.65,3 16,3 Z' />
      </g>
    </g>
  </svg>
)

export default More
