import React from 'react'
import { css } from '@emotion/core'
import { Box } from 'boostly-ui2'
import { Pressed } from '../effects'

const directionToDeg = { right: '0', down: '90', up: '-90', left: '180' }
export default ({ color = 'white', direction = 'right' }) => (
  <Pressed>
    <Box
      css={css`
        transform: rotate(${directionToDeg[direction]}deg);
        transition: 0.15s;
      `}
    >
      <svg
        width='17px'
        height='14px'
        viewBox='0 0 17 14'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
          strokeLinecap='square'
        >
          <g
            transform='translate(8.000000, 7.000000) rotate(-360.000000) translate(-8.000000, -7.000000) translate(1.000000, 2.000000)'
            stroke={color}
            strokeWidth='3'
          >
            <path d='M0.382352941,5 L12.6176471,5' />
            <path d='M8.64705882,-4.54747351e-13 L13.8457402,4.58879453' />
            <path d='M9.41176471,9.84482739 L13.9735663,4.61538462' />
          </g>
        </g>
      </svg>
    </Box>
  </Pressed>
)
