import React from 'react'
import pt from 'prop-types'
import { Close, Box, Absolute, settings, Circle, utils } from 'boostly-ui2'
import Overlay from './overlay'

const makeRenderProps = ({ onRequestClose }) => ({
  Close: () => (
    <Absolute top='25px' right='25px'>
      <Close onClick={onRequestClose} color={settings.colors.midnight} />
    </Absolute>
  ),
  Jewel: ({ children, bg = utils.opacity(settings.colors.midnight, 1) }) => (
    <Box.row x mt='-60px'>
      <Circle size='large' bg={bg}>
        {children}
      </Circle>
    </Box.row>
  ),
  ContentContainer: ({ children }) => (
    <Box
      bg='white'
      borderRadius='20px'
      w='98vw'
      maxWidth='325px'
      m='0 auto'
      p={3}
      position='relative'
    >
      {children}
    </Box>
  ),
  Container: ({ children, hasJewel }) => (
    <Box pt='8vh' w='100%' h='100%'>
      {children}
    </Box>
  )
})

const Modal = ({ zIndex, ...props }) =>
  props.isOpen ? (
    <Overlay isOpen zIndex={zIndex} selector='#___gatsby'>
      {props.children(makeRenderProps(props))}
    </Overlay>
  ) : null

Modal.propTypes = {
  popup: pt.bool,
  isOpen: pt.bool,
  children: pt.func.isRequired
}

export default Modal
