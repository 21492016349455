import React from 'react'
export default ({ size = '40px', color = '#08080C' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 40 40'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='dashboard'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g transform='translate(-140.000000, -52.000000)' fillRule='nonzero'>
        <g transform='translate(140.000000, 52.000000)'>
          <path
            d='M20.0000415,0 C31.0456842,0 40,8.95439768 40,20.0000408 C40,31.0456839 31.0456842,40 20.0000415,40 C8.95439876,40 0,31.0456839 0,20.0000408 L0,20.0000408 C-0.0313467607,8.98571865 8.87196372,0.0315112842 19.8861767,0 C19.9241316,0 19.9620866,0 20.0000415,0 Z'
            fill={color}
          />
          <polygon
            fill='#FFFFFF'
            points='31.0344828 14.8794153 16.8877294 28.9655172 8.96551724 21.1336739 12.1910273 17.9783965 16.8877294 22.5986665 27.8090811 11.7241379'
          />
        </g>
      </g>
    </g>
  </svg>
)
