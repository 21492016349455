import React from 'react'
import { css } from '@emotion/core'
import { Box } from 'boostly-ui2'
const directionToDeg = { right: '90.1', down: '180.1', up: '0', left: '-90.1' }
export const CaretIcon = ({
  direction = 'right',
  color = 'white',
  size = '20px'
}) => (
  <Box
    css={css`
      transform: rotate(${directionToDeg[direction]}deg);
      transition: 0.15s;
    `}
  >
    <Box
      is='svg'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={size}
      height={size}
      viewBox='0 0 307.054 307.054'
      fill={color}
    >
      <g>
        <g>
          <g>
            <path d='M302.445,205.788L164.63,67.959c-6.136-6.13-16.074-6.13-22.203,0L4.597,205.788c-6.129,6.132-6.129,16.069,0,22.201l11.101,11.101c6.129,6.136,16.076,6.136,22.209,0l115.62-115.626L269.151,239.09c6.128,6.136,16.07,6.136,22.201,0l11.101-11.101C308.589,221.85,308.589,211.92,302.445,205.788z' />
          </g>
        </g>
      </g>
    </Box>
  </Box>
)

export default CaretIcon
