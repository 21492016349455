import React from 'react'
import { navigate } from 'gatsby'
import {
  Button,
  Box,
  Col,
  Row,
  P,
  Text,
  Title,
  settings,
  utils
} from 'boostly-ui2'
import { css } from '@emotion/core'
import Modal from 'components/modal'
import { DotLoader } from 'react-spinners'
import { callApi } from './state'
import Shell from 'components/shell.loyalty'
import CapsuleMenu from 'components/capsule-menu'

const SubText = props => <Text size='11px' {...props} />
const InputLabel = ({ children, subText, ...props }) => (
  <Box pb={2} pl={2} {...props}>
    <Text size='16px' display='block'>
      {children}
    </Text>
    {subText && (
      <Box>
        <SubText>{subText}</SubText>
      </Box>
    )}
  </Box>
)

const Coupon = ({ name, expiration, activation, isActive, redemptionUrl }) => {
  const expirationLanguage = isActive ? 'Expires in' : 'Activates on'
  const timeLanguage = isActive ? expiration.split(':') : activation
  return (
    <Box bg={'rgba(255,255,255, .9)'} textAlign='center' px={2} py={3}>
      <Title pb={2}>{name}</Title>
      <Text>
        {expirationLanguage}{' '}
        <span
          display='inline'
          css={css`
            font-family: righteous;
          `}
        >
          {timeLanguage}
        </span>
      </Text>
      {isActive && (
        <Box pt={3} w={'225px'} m={'0 auto'}>
          <Button variant='clean' onClick={() => navigate(redemptionUrl)}>
            Go To Redeem
          </Button>
        </Box>
      )}
    </Box>
  )
}

const Hr = () => (
  <Box bg={utils.opacity(settings.colors.midnight, 0.1)} h={'1px'} />
)
const CouponList = ({ userId }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [coupons, setCoupons] = React.useState([
    {
      id: '123',
      name: 'Super Duper Premium Pizza',
      issuedAt: new Date(),
      // activation: new Date(),
      // fixedActivation:
      expiration: 'DAYS:1',
      redemptionUrl: '',
      isActive: true
      // fixedExpiration:
      // expirationIsDynamic: true,
      // isRedeemed: false
    },
    {
      id: '456',
      name: 'Super Duper Premium Pizza',
      issuedAt: new Date(),
      activation: 'DAYS:7',
      fixedActivation: new Date(),
      expiration: 'HOURS:1',
      // expirationIsDynamic: true,
      isRedeemed: false,
      redemptionUrl: '',
      isActive: false
    }
  ])

  return (
    <Box>
      {coupons.map((c, i) => {
        return (
          <Box mb={2} key={c.id}>
            <Hr />
            <Coupon {...c} />
            <Hr />
          </Box>
        )
      })}
    </Box>
  )
}

const Sub = ({ perkName, perkCost, isActive }) => {
  return (
    <Box bg='rgba(255,255,255, .9)' px={3} py={3}>
      <Row space='between' y>
        <Box>
          <Title>{perkName}</Title>
          <Text>
            <span
              css={css`
                font-family: righteous;
              `}
            >
              ${perkCost}
            </span>{' '}
            PER MONTH
          </Text>
        </Box>
        <Box>
          <Title>Edit</Title>
        </Box>
      </Row>
    </Box>
  )
}
const SubList = ({ userId }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [subs, setSubs] = React.useState([
    {
      id: '123',
      perkName: 'Weekly Promos',
      perkCost: 5,
      isActive: true
    }
  ])

  return (
    <Box>
      {subs.map((sub, i) => {
        return (
          <Box mb={2} key={sub.id}>
            <Hr />
            <Sub {...sub} />
            <Hr />
          </Box>
        )
      })}
    </Box>
  )
}

const tabs = [
  {
    label: 'Coupons',
    renderMain: ({ userId }) => <CouponList userId={userId} />
  },
  {
    label: 'Subscriptions',
    renderMain: ({ userId }) => <SubList userId={userId} />
  }
]
const MemberProfile = ({ customerSlug }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [user, setUser] = React.useState({})
  const [activeTab, setActiveTab] = React.useState(0)

  const onTabSelect = index => {
    setActiveTab(index)
  }
  const loadMember = async () => {
    const { campaign } = await callApi.loadCampaign({
      slug: customerSlug
    })
    setUser(campaign)
    setIsLoading(false)
  }

  React.useEffect(() => {
    // loadMember()
  }, [])

  const { renderMain } = tabs[activeTab]
  return (
    <Shell>
      {({ Nav, NavHeight, Headboard, Notification }) => (
        <>
          <Notification>
            <P size='14px' textAlign='center'>
              Something something instructions on how to save this as an “app”
              on your phone or if desktop option to send text to phone w/ link
              to profile
            </P>
          </Notification>
          <Headboard>
            <Box>
              <>
                <Box py={2} m={'0 auto'} maxWidth='280px'>
                  <CapsuleMenu
                    dimensions={{
                      h: 26,
                      w: 120
                    }}
                    defaultIndex={activeTab}
                    onSelect={onTabSelect}
                    color={settings.colors.midnight}
                    fontSize={'16px'}
                  >
                    {tabs.map(t => t.label)}
                  </CapsuleMenu>
                </Box>
              </>
            </Box>
          </Headboard>
          <Box p={4}>{renderMain({ userId: user.id })}</Box>
        </>
      )}
    </Shell>
  )
}

export default MemberProfile

const Heading = ({ children }) => <Title size={'18px'}>{children}</Title>

const Header = ({ heading, description }) => {
  return (
    <Col x py={3}>
      <Heading>{heading}</Heading>
      <Box maxWidth='250px' pt={2}>
        <P textAlign='center' lineHeight='1.5' size='14px'>
          {description}
        </P>
      </Box>
    </Col>
  )
}
