import React from 'react'
import { Button, Box, Row, P, Text, settings, Input } from 'boostly-ui2'
import { css } from '@emotion/core'
import { useInput, useBoolean } from 'react-hanger'
import { DotLoader } from 'react-spinners'
import { useStripeConnect } from 'hooks/stripe'
import { useAuth } from 'state/auth'
import { callApi } from './state'
import { Check } from 'components/icons'
import Modal from 'components/modal'

const SubText = props => <Text size='11px' {...props} />
const InputLabel = ({ children, subText, ...props }) => (
  <Box pb={2} pl={2} {...props}>
    <Text size='16px' display='block'>
      {children}
    </Text>
    {subText && (
      <Box>
        <SubText>{subText}</SubText>
      </Box>
    )}
  </Box>
)

const UserForm = ({ onCreateSuccess }) => {
  const auth = useAuth()
  const isLoading = useBoolean(false)
  const [error, setError] = React.useState()
  const name = useInput('')
  const phone = useInput('')

  const onSubmit = async () => {
    isLoading.setTrue()
    const { result, user } = await callApi.createUser({
      phone: phone.value,
      name: name.value
    })
    console.log({ result, user })
    if (result === 'ALREADY_EXISTS') {
      // we reaaaaally should text to verify that it's them..
    }

    if (result === 'INVALID_MOBILE_NUMBER') {
      isLoading.setFalse()
      return setError({
        message: 'Valid mobile number that can receive texts required'
      })
    }

    await onCreateSuccess({ user })
    auth.dispatch({
      type: auth.actions.NEW_USER,
      payload: {
        user
      }
    })
  }

  return (
    <>
      <P size='14px' pb={3} pl={2}>
        Let’s start by getting what we’ll need to deliver you your perks.
      </P>
      <InputLabel>Name</InputLabel>
      <Input {...name} />

      <Box pt={3}>
        <InputLabel subText='The number we’ll use to text your perks to'>
          Mobile Phone
        </InputLabel>
        <Input {...phone} />
        {error && (
          <Box pl={2}>
            <SubText color={settings.colors.red}>{error.message}</SubText>
          </Box>
        )}
      </Box>

      <Row x='end' py={4}>
        <Box w={isLoading.value ? '44px' : '50%'} transition='.25s'>
          {isLoading.value ? (
            <Button variant='clean'>
              <Row x>
                <DotLoader size={20} color='white' />
              </Row>
            </Button>
          ) : (
            <Button variant='clean' onClick={onSubmit}>
              Next
            </Button>
          )}
        </Box>
      </Row>
    </>
  )
}
const Checkout = ({ perk, onCancel }) => {
  const auth = useAuth()
  const [user, setUser] = React.useState(auth.user)
  const [sessionId, setSessionId] = React.useState()
  const [showConfirmation, setShowConfirmation] = React.useState(false)

  const onUserCreateSuccess = async ({ user }) => {
    const response = await callApi.createCheckoutSession({
      userId: user.id,
      stripeAccountId: perk.stripeAccountId,
      stripePlanId: perk.stripePlanId,
      stripeCustomerId: user.stripeId,
      successEndpoint: `/loyalty/member/${user.id}`,
      successUrl: `http://localhost:8000/loyalty/member/${user.id}`
    })
    console.log({ response })
    setUser(user)
    setShowConfirmation(true)
    setSessionId(response.session.id)
  }

  const proceedToCheckout = async ({ sessionId }) => {
    console.log({ sessionId })
    // stripe magic here
    const stripe = await useStripeConnect(perk.stripeAccountId)
    stripe.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as parameter here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId
    })
  }

  return (
    <Modal isOpen onRequestClose={onCancel}>
      {({ ContentContainer, Container, Close, Jewel }) => (
        <Container>
          <ContentContainer>
            {showConfirmation ? (
              <Box py={3}>
                <Row x>
                  <Check />
                </Row>
                <Row x py={4}>
                  <Text size={3}>👋 Hey, {user.firstName}</Text>
                </Row>
                <Box px={4}>
                  <Button
                    variant='clean'
                    onClick={() => proceedToCheckout({ sessionId })}
                  >
                    Proceed To Checkout
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                <Box pt='30px'>
                  <Close />
                </Box>
                <Box pt={3}>
                  <UserForm onCreateSuccess={onUserCreateSuccess} />
                </Box>

                <Box
                  pl={2}
                  pb={2}
                  opacity='65%'
                  css={css`
                    line-height: 0.9;
                  `}
                >
                  <SubText>
                    By proceeding, I agree to receive automated marketing
                    messages from BRAND NAME & Boostly Inc. at the number
                    provided. Consent is not required to purchase goods or
                    services. Msg & data rates may apply. 4 msgs/mo. Reply STOP
                    to Cancel.
                  </SubText>
                </Box>
              </>
            )}
          </ContentContainer>
        </Container>
      )}
    </Modal>
  )
}

export default Checkout
