import React from 'react'
const Flash = () => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g fill='#FFFFFF' fillRule='nonzero'>
        <path d='M22.2927637,23.707034 C22.4877666,23.9020395 22.7437422,24 22.9997177,24 C23.2556933,24 23.5116689,23.9020395 23.7066718,23.707034 C24.0977761,23.3159245 24.0977761,22.6840335 23.7066718,22.292924 L16.7288652,15.3150242 L19.7498532,11 C19.9107992,10.7850381 19.9997864,10.5189884 19.9997864,10.250009 C19.9997864,9.56117286 19.4387641,9.00014306 18.749754,9.00014306 L13.3438718,9.00014306 L14.2439977,1.39712718 C14.2469273,1.36819676 14.2489415,1.27920838 14.2489415,1.25009487 C14.249857,0.561258694 13.6890177,0 13,0 C12.6079878,0 12.2339119,0.188093284 11.9779363,0.531229684 L7.93908834,6.52513005 L1.70723634,0.293194844 C1.31613201,-0.0977316148 0.684249487,-0.0977316148 0.293328247,0.293194844 C-0.0977760823,0.684121257 -0.0977760823,1.31619535 0.293328247,1.70712177 L22.2927637,23.707034 Z' />
        <path d='M4.12436042,11.8416227 C3.96118665,12.0594256 3.87096774,12.3289893 3.87096774,12.6015213 C3.87096774,13.299641 4.43975543,13.8678952 5.13811678,13.8678952 L9.616206,13.8678952 L8.69155475,22.6028329 C8.68747074,22.6291771 8.68654258,22.7070963 8.68654258,22.7334405 C8.68654258,23.4315602 9.25533026,24 9.95387729,24 C10.3513232,24 10.7303913,23.8094688 10.981742,23.472004 L14.7096774,18.1497456 L5.84483178,9.29032258 L4.12436042,11.8416227 Z' />
      </g>
    </g>
  </svg>
)

export default Flash
