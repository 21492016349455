import React from 'react'
import posed from 'react-pose'
import SplitText from 'react-pose-text'
import styled from '@emotion/styled'

export const Pressed = posed.div({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.8 }
})

export const CalculatedText = ({ children }) => (
  <SplitText
    initialPose='exit'
    pose='enter'
    charPoses={{
      exit: { opacity: 0, y: 20 },
      enter: {
        opacity: 1,
        y: 0,
        delay: ({ charIndex }) => charIndex * 30
      }
    }}
  >
    {children}
  </SplitText>
)

export const Hover = posed(
  styled.div`
    cursor: pointer;
  `
)({
  hoverable: true,
  init: {
    scale: 1
  },
  hover: {
    scale: 1.1
  }
})
