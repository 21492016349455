import api from 'api'

export const loadCampaign = async ({ slug }) =>
  api({
    method: api.methods.POST,
    endpoint: '/loyalty/campaigns/load-by-slug',
    data: { slug }
  })

export const loadPerk = async ({ perkId }) =>
  api({
    method: api.methods.POST,
    endpoint: '/loyalty/perks/load',
    data: { perkId }
  })

export const createUser = async ({ name, phone }) =>
  api({
    method: api.methods.POST,
    endpoint: '/loyalty/users/create',
    data: { name, phone }
  })

export const createCheckoutSession = async ({
  successEndpoint,
  stripeAccountId,
  stripePlanId,
  userId
  // stripeCustomerId
}) =>
  api({
    method: api.methods.POST,
    endpoint: '/loyalty/subscriptions/request',
    data: {
      successEndpoint,
      stripeAccountId,
      stripePlanId,
      userId
      //  stripeCustomerId
    }
  })

export const callApi = {
  loadCampaign,
  loadPerk,
  createUser,
  createCheckoutSession
}
