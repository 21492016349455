import React from 'react'
import { Router } from '@reach/router'
import { AuthProvider } from 'state/auth'
import { Perks, Member } from 'views/loyalty'
// const PrivateRoute = ({ component: Component, location, ...rest }) => {
//   if (!false && location.pathname !== `/app/login`) {
//     // If the user is not logged in, redirect to the login page.
//     navigate(`/login`)
//     return null
//   }

//   return <Component {...rest} />
// }
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe('pk_test_bQ6yCBO6aa7CjwtvDDEhlekS')

const App = () => (
  <AuthProvider>
    <Elements stripe={stripePromise}>
      <Router>
        <Perks path='/loyalty/perks/:customerSlug' />
        <Member path='/loyalty/member/:memberId' />
      </Router>
    </Elements>
  </AuthProvider>
)

export default App
