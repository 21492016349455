import axios from 'axios'

const apiRoot = process.env.API_ROOT || 'http://localhost:9000'
const baseEndpoint = `${apiRoot}/api`
const api = async $ => {
  const config = composeConfig($)
  const response = await axios({
    endpoint: apiRoot + config.endpoint,
    ...config
  })
  return maybeParse(response.data)
}
api.methods = {
  GET: 'GET',
  POST: 'POST'
}
export default api

function maybeParse(data) {
  if (!isJSON(data)) {
    return data
  }

  return JSON.parse(data)
}

function isJSON(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const DEFAULT_METHOD = `GET`
function composeConfig(callAPI) {
  let {
    apiRoot,
    url,
    endpoint,
    method,
    headers,
    transformResponse,
    params,
    data
  } = callAPI

  if (!url) {
    if (!endpoint || typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.')
    }

    url = baseEndpoint + endpoint
  }

  if (!method) {
    method = DEFAULT_METHOD
  }

  if (!headers) {
    headers = { 'Content-Type': 'application/json' }
  }

  return { url, method, headers, transformResponse, params, data }
}
