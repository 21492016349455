/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
// import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Box, settings } from 'boostly-ui2'
const Container = styled(Box)`
  position: relative;
  max-width: 450px;
  margin: 0 auto;
`

const Shell = React.forwardRef(({ children }, ref) => (
  <div
    ref={ref}
    style={{ minHeight: `100vh`, backgroundColor: settings.colors.lilac }}
  >
    {children({ Container })}
  </div>
))

Shell.propTypes = {
  // children: PropTypes.node.isRequired
}

export default Shell
