import React from 'react'
import { DotLoader } from 'react-spinners'
import { Button, Box, Col, Row, P, Title, settings } from 'boostly-ui2'

import Shell from 'components/shell.loyalty'
import CapsuleMenu from 'components/capsule-menu'

import { callApi } from './state'
import Checkout from './checkout'

// const STATES = {
//   CAMPAIGN_NOT_LOADED: 'CAMPAIGN_NOT_LOADED',
//   CAMPAIGN_LOADING_ERROR: 'CAMPAIGN_LOADING_ERROR',
//   CAMPAIGN_LOADED_NO_PERKS: 'CAMPAIGN_LOADED_NO_PERKS',
//   CAMPAIGN_LOADED_ONE_PERK: 'CAMPAIGN_LOADED_ONE_PERK',
//   PERK_NOT_LOADED: 'PERK_NOT_LOADED',
//   PERK_LOADING_ERROR: 'PERK_LOADING_ERROR',
//   PERK_LOADED: 'PERK_LOADED'
// }

const Perks = ({ customerSlug }) => {
  const defaultPerkIndex = 0
  const [isLoading, setIsLoading] = React.useState(true)
  const [campaign, setCampaign] = React.useState(false)
  const [perksById, setPerksById] = React.useState({})
  const [activePerk, setActivePerk] = React.useState({})
  const [isLoadingPerk, setIsLoadingPerk] = React.useState(false)

  const [goToCheckout, setGoToCheckout] = React.useState(false)
  const onCheckoutCancelRequest = () => {
    setGoToCheckout(false)
  }
  const onSubscribeRequest = () => setGoToCheckout(true)
  const onSelect = async index => {
    const perkId = campaign.perks[index]
    const existingPerk = perksById[perkId]

    let perk = existingPerk
    if (!existingPerk) {
      perk = await loadPerk(perkId)
    }

    setActivePerk(perk)
  }

  const loadCampaign = async () => {
    const { campaign } = await callApi.loadCampaign({
      slug: customerSlug
    })
    setCampaign(campaign)
    setIsLoading(false)

    const perk = await loadPerk(campaign.perks[defaultPerkIndex])
    setActivePerk(perk)
  }

  const loadPerk = async perkId => {
    setIsLoadingPerk(true)
    const { perk } = await callApi.loadPerk({ perkId })
    setIsLoadingPerk(false)

    setPerksById({
      ...perksById,
      [perkId]: perk
    })

    return perk
  }

  React.useEffect(() => {
    loadCampaign()
  }, [])

  if (isLoading) {
    return (
      <Shell>
        {({ Nav, NavHeight, Headboard }) => (
          <>
            <Nav />
            <Headboard minHeight='100px'>
              <Box pt={NavHeight + 'px'}>
                <Row x y height='100%'>
                  <DotLoader color={settings.colors.midnight} size={32} />
                </Row>
              </Box>
            </Headboard>
          </>
        )}
      </Shell>
    )
  }

  let Main
  if (isLoadingPerk) {
    Main = () => (
      <Row x pt='10%'>
        <DotLoader color={settings.colors.midnight} size={32} />
      </Row>
    )
  } else if (activePerk) {
    Main = ({ title, description, cost }) => (
      <Box>
        <Box opacity={0.5} maxWidth='290px' m='0 auto' textAlign='center'>
          <Title>{title}</Title>
        </Box>
        <Box textAlign='center' pt={1}>
          <Title size={5}>${cost}</Title>
          <Title>PER MONTH</Title>
        </Box>
        <Box maxWidth='200px' m='0 auto' py={3}>
          <Button onClick={onSubscribeRequest} variant='clean'>
            Subscribe
          </Button>
        </Box>
        <Box maxWidth='290px' m='0 auto' textAlign='center'>
          <P>{description}</P>
        </Box>
      </Box>
    )
  }
  const showMenu = campaign.perks.length > 1
  return (
    <Shell>
      {({ Nav, NavHeight, Headboard }) => (
        <>
          {goToCheckout && (
            <Checkout perk={activePerk} onCancel={onCheckoutCancelRequest} />
          )}
          <Nav />
          <Headboard>
            <Box pt={NavHeight + 'px'}>
              <>
                <Header
                  {...{
                    heading: campaign.brand + ' Perks',
                    description: campaign.description,
                    menu: {
                      onSelect,
                      options: campaign.perkCosts
                    }
                  }}
                />
                <Box
                  py={2}
                  m='0 auto'
                  maxWidth={75 * campaign.perkCosts.length + 'px'}
                >
                  {showMenu ? (
                    <CapsuleMenu
                      defaultIndex={0}
                      onSelect={onSelect}
                      color={settings.colors.midnight}
                      fontSize='16px'
                    >
                      {campaign.perkCosts.map(c => '$' + c)}
                    </CapsuleMenu>
                  ) : null}
                </Box>
              </>
            </Box>
          </Headboard>
          <Box p={4}>
            <Main {...activePerk} />
          </Box>
        </>
      )}
    </Shell>
  )
}

export default Perks

const Heading = ({ children }) => <Title size='18px'>{children}</Title>

const Header = ({ heading, description }) => {
  return (
    <Col x py={3}>
      <Heading>{heading}</Heading>
      <Box maxWidth='250px' pt={2}>
        <P textAlign='center' lineHeight='1.5' size='14px'>
          {description}
        </P>
      </Box>
    </Col>
  )
}
