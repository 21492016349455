import React from 'react'
import { Box, Row, settings, utils, Absolute } from 'boostly-ui2'
import styled from '@emotion/styled'
import { Arrow } from './icons'
import Shell from './shell'
import Swipe from './swipe'
import { CloseOutlined } from './icons/close'

const Notification = ({ children, onRequestClose = () => {} }) => {
  return (
    <Box
      position='relative'
      bg={'white'}
      borderBottom={`solid 1px ${utils.opacity(settings.colors.midnight, 0.1)}`}
    >
      <Box maxWidth='80vw' m={'0 auto'} py={2}>
        {children}
      </Box>
      <Absolute top='40%' right='10px'>
        <CloseOutlined />
      </Absolute>
    </Box>
  )
}

const NavHeight = 30
const Nav = ({ goBackTo, left, center, right }) => (
  <Row
    position='absolute'
    left='0'
    top='0'
    space='around'
    y
    w='100%'
    height={NavHeight + 'px'}
  >
    <Row w='33%'>
      {goBackTo ? (
        <a href={goBackTo}>
          <Arrow color={settings.colors.purple} direction='left' />
        </a>
      ) : (
        left
      )}
    </Row>
    <Row w='33%'>{center}</Row>
    <Row w='33%'>{right}</Row>
  </Row>
)
const headboardBorderRadius = '10px'
const Headboard = styled(Box)`
  transition: all 1s ease;
  background-color: white;
  border-bottom-left-radius: ${headboardBorderRadius};
  border-bottom-right-radius: ${headboardBorderRadius};
  padding-right: ${headboardBorderRadius};
  padding-left: ${headboardBorderRadius};
`
const loyaltyShell = ({ children }) => (
  <Shell>
    {({ Container }) => (
      <Box position='relative'>
        {children({ Nav, NavHeight, Container, Headboard, Notification })}
      </Box>
    )}
  </Shell>
)

export default loyaltyShell
